import { getAllAssets } from "../genericFunctions/getAssets";
import { uploadAssetsApi } from "../services/api";
import { assetDataActions } from "../store/assets";

export const uploadWidthHeightImage = async (file, key, toast, dispatch, headers, screen) => {
  if(file === undefined){
    return true;
  }
  dispatch(dispatch(assetDataActions.disableSave(true)));
  const formData = new FormData();
  formData.append("screen", screen);
  formData.append(key, file);
  const getCMSResponse = (response) => {
    if (response.result === "SUCCESS") {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Image updated successfully",
      });
      getAllAssets(toast, dispatch, headers);
    } else if (response.result === "FAILED") {
      const error = response.error;
      if (response.error.summary === 'Request Entity Too Large' || response.status === 413) {
        toast.current.show({
          severity: error.severity,
          summary: "Error",
          detail: "Image size should not exceed 1MB",
        });
      } else {
      toast.current.show({
        severity: error.severity,
        summary: "Error",
        detail: error.errorMsg
          ? error.errorMsg
          : error.summary || "Image updated failed",
      });
    } 
  };
}
  uploadAssetsApi(formData, headers, dispatch, getCMSResponse);
};

export const orderStatuses = [
  { label: "COMPLETED", value: "COMPLETED" },
  { label: "NEW", value: "NEW" },
  { label: "PAYMENT CANCELLED", value: "PAYMENT_CANCELLED" },
  { label: "PAYMENT FAILED", value: "PAYMENT_FAILED" },
  { label: "PAID", value: "PAID"},
  { label: "PURCHASE FAILED", value: "PURCHASE_FAILED" },
  { label: "PURCHASE REVOKED", value: "PURCHASE_REVOKED" },
  { label: "REFUNDED", value: "REFUNDED" },
];
export const paymentGatways = [
  { label: "ApplePay", value: "ApplePay" },
  { label: "Debit / Credit Card", value: "Stripe" },
  { label: "GooglePay", value: "GooglePay" },
  { label: "None", value: "none" },
  { label: "Paypal", value: "Paypal" }
];
